/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
// core components
import Button from "./Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
	const classes = useStyles();
	return (
		<List className={classes.list}>
			<ListItem className={classes.listItem}>
				<Tooltip
					id="instagram-github"
					title="View us on Github"
					placement={window.innerWidth > 959 ? "top" : "left"}
					classes={{tooltip: classes.tooltip}}
				>
					<Button
						color="transparent"
						href="https://github.com/coreyphillips/moonshine"
						target="_blank"
						className={classes.navLink}
					>
						<i className={classes.icons + " fab fa-github"}/>
					</Button>
				</Tooltip>
			</ListItem>
			<ListItem className={classes.listItem}>
				<Tooltip
					id="instagram-twitter"
					title="Follow us on Twitter"
					placement={window.innerWidth > 959 ? "top" : "left"}
					classes={{tooltip: classes.tooltip}}
				>
					<Button
						href="https://twitter.com/coreylphillips"
						target="_blank"
						color="transparent"
						className={classes.navLink}
					>
						<i className={classes.socialIcons + " fab fa-twitter"}/>
					</Button>
				</Tooltip>
			</ListItem>
		</List>
	);
}
