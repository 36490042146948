/*eslint-disable*/
import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "./GridContainer.js";
import GridItem from "./GridItem.js";
import Button from "./Button.js";
import appStoreLogo from './assets/img/appStoreLogo.svg'
import googlePlayLogo from './assets/img/googlePlayLogo.png'
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(styles);

export default function SectionDownload() {
	const classes = useStyles();
	return (
		<div className={classes.section}>
			<div className={classes.container}>
              <GridContainer className={classes.textCenter} justify="center">
                <GridItem xs={12} sm={12} md={8}>
                  <h3>Moonshine is a homebrewed, open-source, non-custodial, Bitcoin & Litecoin Electrum wallet.</h3>
                </GridItem>
              </GridContainer>
              
				<GridContainer className={classes.textCenter} justify="center">
					<GridItem xs={12} sm={12} md={8}>
						<h2>Available on Android & iOS</h2>
					</GridItem>
					<GridItem xs={12} sm={8} md={6}>
						<a href="https://play.google.com/store/apps/details?id=com.kisswallet&hl=en_US">
							<img style={{width: "200px"}} alt='Get it on Google Play' src={googlePlayLogo}/>
						</a>
						<a href="https://apps.apple.com/us/app/moonshine-wallet/id1449385577?ls=1">
							<img style={{width: "170px"}} alt='Download on the App Store' src={appStoreLogo}/>
						</a>
					</GridItem>
				</GridContainer>
				<br/>
				<br/>
				
				<GridContainer className={classes.textCenter} justify="center">
					<GridItem xs={12} sm={12} md={8}>
						<h2>Open Source</h2>
						<h4>
							Want to take a look at the code or build it on your own? Go for it.
						</h4>
						<Button color="github" href="https://github.com/coreyphillips/moonshine">
							<i className={classes.socials + " fab fa-github"}/> Github
						</Button>
					</GridItem>
				</GridContainer>
				<br/>
				<br/>
    
				<GridContainer className={classes.textCenter} justify="center">
					<GridItem xs={12} sm={12} md={8}>
						<h2>Key Features:</h2>
					</GridItem>
					<Grid container spacing={2}>
						<Grid item xs justify="center" alignItems="center">
							<h4>Non-Custodial</h4> <h4>RBF</h4> <h4>Supports Multiple Wallets</h4> <h4>Custom Fee Selection</h4>
						</Grid>
						<Grid item xs justify="center" alignItems="center">
                          <h4>Native Segwit</h4> <h4>Biometric + Pin Authentication</h4> <h4>Connect to Electrum Server</h4> <h4>BIP39 Passphrase Support</h4>
						</Grid>
						<Grid item xs justify="center" alignItems="center">
                          <h4>Coin Control</h4> <h4>Sign & Verify Messages</h4> <h4>UTXO Blacklisting</h4> <h4>Bitcoin/Litecoin Mainnet & Testnet</h4>
						</Grid>
					</Grid>
				</GridContainer>
				
				<div className={classes.textCenter + " " + classes.sharingArea}>
					<GridContainer justify="center">
						<h3>Need some help? Contact Support</h3>
					</GridContainer>
                  <Button color="github" href="mailto:support@moonshinewallet.com">
                    support@moonshinewallet.com
                  </Button>
				</div>
				<div className={classes.textCenter + " " + classes.sharingArea}>
					<a href="https://gist.githubusercontent.com/coreyphillips/4183d80e190c50920ca78aac9df7206c/raw/507b0535b978930a94f90b7d9e4f854beca5f49d/privacy.txt">
						<h4>View Terms & Conditions/Privacy Policy</h4>
					</a>
				</div>
			</div>
		</div>
	);
}
